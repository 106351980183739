
import Api from "@/services/Api";
import { computed, defineComponent, onMounted, ref, Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Modal } from "bootstrap";
import Title from "@/components/Title.vue";
import Pagination from "@/components/Pagination.vue";
import Button from "@/components/Button.vue";
// import ModalCreateEditBandeira from "./components/ModalCreateEditBandeira.vue";
import useEmitter from "@/composables/Emmiter";
import ModalEditPlanoLojaVue from "./components/ModalEditPlanoLoja.vue";
import moment from 'moment';
import 'moment/locale/pt-br';
import { LojaPlano } from "@/layout/header/partials/filters/FilterCustos.vue";
import { string } from "yup";

interface LojasData {
    [codConcessionaria: string]: Loja[] | null;
}

interface Loja {
    nomeFantasia: string | null;
    placa: string | null;
    manual: number | null;
    placaValor: number | null | string;
    manualValor: number | null | string;
    id: number | null;
}

interface Dados {
    lojas: number[] | string[];
    grupo: number[] | string[];
    consultor: number[] | string[];
    dataInicio: any;
}

interface LojasGrupoConsultor {
    nome: string;
    placaValor: number | null | string;
    manualValor: number | null | string;
    buscas: {
        [tipo: string]: number;
    };
    codConcessionaria: number[] | null;
}

export default defineComponent({
  name: "CustosApp",
  components: {Title, Button, ModalEditPlanoLojaVue, Pagination },
  props: [],
  setup() {
    const router = useRouter();
    const route = useRoute();
    const emitter = useEmitter();
    const loading = ref(false)
    const lojas:Ref<Loja[] | null> = ref(null)
    const lojasPaginate:Ref<Loja[] | null> = ref(null)
    const grupoOrConsultorObj:Ref<LojasGrupoConsultor> = ref({
      nome: '',
      placaValor: 0,
      manualValor: 0,
      buscas: {
          manual: 0,
          placa: 0,
      },
      codConcessionaria: null,
    })
    const lojasGrupoConsultor:Ref<any | null> = ref(null)
    const dados:Ref<Dados> = ref({
      lojas: [], 
      grupo: [], 
      consultor: [], 
      dataInicio: null
    })
    const total:Ref<any> = ref(150)
    const grupoOrConsultor:Ref<string | null> = ref(null)

    async function getCustos(param){   
      try {
        loading.value = true
        grupoOrConsultor.value = null
        lojas.value = null
        const res = await Api.post('custosApp', {...param});
        if(res.data.lojas !== 0 && res.data.lojas.length){
          lojas.value = res.data.lojas;
          firstPage();
        } 
        if(res.data.grupo !== 0) {
          grupoOrConsultorObj.value = res.data.grupo
          lojasGrupoConsultor.value = res.data.grupo.codConcessionaria; 
          grupoOrConsultor.value = 'grupo';
        }
        if(res.data.consultor !== 0) {
          grupoOrConsultorObj.value = res.data.consultor
          lojasGrupoConsultor.value = res.data.consultor.codConcessionaria; 
          grupoOrConsultor.value = 'consultor';
        }
        loading.value = false
      } catch (error) {
        console.log('ola', error)
      }   
    }

    function mergeLojas(){
      dados.value = {...dados.value, lojas: lojasGrupoConsultor.value}
      getCustos(dados.value)
      return 
    }

    function paginateFront(pageParam) {
      lojasPaginate.value = pageParam;
    }

    emitter.on("custosFilter", (valor: any) => {
      dados.value = valor.paramsFilter
      dados.value = {...dados.value, dataInicio: formatarDataParaBanco(dados.value.dataInicio)}
      getCustos(dados.value)
    })

    function formatarDataParaBanco(data: any) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss');
    } 

    function formatarDataParaTemplate(data: any) {
      moment.locale('pt-br');
      return moment(data).format('MMMM');
    } 

    
    function somarCusto(qtdPlaca, valorPlaca, qtdManual, valorManual){
      const totalCusto:Ref<any> = ref(0)
      totalCusto.value = ((qtdPlaca || 0) * valorPlaca + (qtdManual || 0) * valorManual).toFixed(2);
      return parseFloat(totalCusto.value).toLocaleString("pt-BR", {style: "currency",currency: "BRL"});
    }

    const totalPlacas:Ref<any> = ref(0);
    const totalManuais:Ref<any> = ref(0);
    const totalCusto = computed(() => {
      let total = 0;
      
      lojas.value && lojas.value.forEach((loja) => {
        const custoLoja = ((Number(loja.placa) || 0) * Number(loja.placaValor || 0) + (Number(loja.manual) || 0) * Number(loja.manualValor || 0));
        total += custoLoja;
      });
      
      totalPlacas.value = lojas.value && lojas.value.reduce((acc, loja) => acc + (Number(loja.placa) || 0), 0);
      totalManuais.value = lojas.value && lojas.value.reduce((acc, loja) => acc + (Number(loja.manual) || 0), 0);
      
      return parseFloat(total.toFixed(2)).toLocaleString("pt-BR", {style: "currency",currency: "BRL"});
    });

    const firstPage = () => {
      lojasPaginate.value = lojas.value?.slice(0,9) as any;
    }
    
    return {lojas, route, lojasPaginate, paginateFront, formatarDataParaTemplate, total, getCustos, mergeLojas, grupoOrConsultor, grupoOrConsultorObj, loading, somarCusto, totalPlacas, totalManuais, totalCusto, dados };
    
  },
});
